import { Box } from "@chakra-ui/react";
import Link from "next/link";
import React from "react";

import { NavItemProps } from "./types";

export const NavItem: React.FC<NavItemProps> = ({
  url,
  name,
  onClick,
  external = false,
}) => (
  <Box
    fontSize={["0.875rem", null, null, "1rem"]}
    padding="0 0.5rem"
    textTransform="uppercase"
    onClick={(e) => onClick?.(e)}
  >
    {external ? (
      <a href={url}>{name}</a>
    ) : (
      <Link href={url}>
        <a>{name}</a>
      </Link>
    )}
  </Box>
);
